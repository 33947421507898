import { color } from '@mui/system'
import React from 'react'
import '../style/Gdpr.css'
export default function TextGdpr() {
  return (
    <div className={'gdpr login-page'}>
       <h2>Acord privind protecția datelor personale</h2>
       <div className={'regulament'}>			



<p>Începând cu data de 25 mai 2018, Regulamentul 2016/679/UE privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date (în continuare „Acordul”) este aplicat de toate statele Uniunii Europene. Prin intermediul acestui Acord se dorește crearea unui cadru legislativ unitar și uniform pe teritoriul Uniunii Europene care să nu mai necesite măsuri naționale de implementare.</p>

<p>Organizația studențească „Sindicatul Studenților din Facultatea de Cibernetică, Statistică și Informatică Economică” (SiSC), denumit în continuare organizator, având cod de identificare fiscală 8905986, reprezentat prin Chiscăneanu Răzvan-Georgian, în calitate de președinte, se aliniază prevederilor Acordului și vă informează cu privire la următoarele aspecte:</p>

<p><strong>În calitatea sa de organizator, Sindicatul Studenților din Facultatea de Cibernetică, Statistică și Informatică Economică, vă informează cu privire la prelucrarea următoarelor categorii de date cu caracter personal:</strong></p>
Datele personale pe care le prelucrăm sunt, în principal, datele furnizate prin intermediul formularului de înscriere care vor fi stocate în baza noastră de date. Acestea se referă la: nume și prenume, adresa de email, numărul de telefon, nivelul studiilor și datele oferite în general prin intermediul unui Curriculum Vitae.
Pe lângă datele furnizate prin intermediul formularului, vor fi prelucrate și date reprezentând imaginea, comportamentul și, in anumite cazuri, numele și vocea persoanei fizice care vor fi prelucrate de organizator prin realizarea de fotografii și/sau filmari susținând organizatorul în implementarea și organizarea fiecărui eveniment, precum și în orice scopuri compatibile, conexe si corelate.


<p><strong>1. Scopurile în care sunt prelucrate datele cu caracter personal</strong></p>

<p>Prelucrăm datele personale pentru promovarea proiectului „Spring IT” și pentru confirmarea participării la workshopurile la care o persoană s-a înscris.
Datele preluate sunt următoarele: nume și prenume, adresa de email, numărul de telefon, nivelul studiilor și datele oferite în general prin intermediul unui Curriculum Vitae. De asemenea, CV-urile colectate sunt destinate companiilor partenere în vederea unei viitoare colaborări între acestea și participanți. 
<strong>**în situația în care va fi necesar ca, ulterior, datele să vă fie prelucrate și în alt scop decât cele pentru care au fost colectate inițial, mai sus menționate, înainte de orice prelucrare, vă vom furniza informații cu privire la noile scopuri și orice alte informații suplimentare relevante.</strong></p>

<p><strong>2. Temeiul prelucrării</strong></p>

<p><strong>Temeiul</strong> pentru care colectăm și administrăm datele dumneavoastră personale este, în primul rând,<strong> consimțământul dumneavoastră</strong> acordat cu prilejul desfășurării proiectului „Spring IT”. </p>

<p><strong>3. Destinatarii datelor prelucrate</strong></p>

<p>Datele sunt folosite în cadrul activității interne a organizatorului, privind proiectul „Spring IT”. CV-urile colectate sunt destinate companiilor partenere în vederea unei viitoare colaborări între acestea și participanți. </p>

<p><strong>4. Durata stocării</strong></p>

<p>Datele dumneavoastră personale sunt prelucrate din momentul înscrierii pe o perioadă nedeterminată.<br></br>
<strong>Datele dumneavoastră nu vor putea fi folosite în alte scopuri și nici nu vor putea face obiectul unor decizii automate bazate exclusiv pe prelucrarea automată.</strong></p>

<strong>5. Colectarea datelor</strong><br></br><br></br>

Datele vor fi colectate prin intermediul formularului, folosind câmpurile completate de către participanți, cu informațiile regăsite la punctul 1.

<strong>6. Securitatea datelor personale</strong><br></br><br></br>

Organizatorul respectă și protejează confidențialitatea datelor personale din formularul de înscriere. Orice informație personală este prelucrată și folosită conform politicii de confidențialitate a datelor personale și nu va fi folosită în niciun mod care contravine acestei politici. Organizatorul este singura entitate care are acces la date.

<strong>Vă aducem la cunoștință că aveți următoarele drepturi:</strong><br></br>
-<strong>dreptul de a avea acces la datele dumneavoastră, în mod gratuit:</strong> puteți solicita să vi se comunice categoriile de date cu caracter personal care sunt prelucrate, scopul în care are loc prelucrarea, destinatarii cărora le-au fost sau vor fi comunicate, perioada pentru care se preconizează că vor fi stocate;<br></br><br></br>

-<strong>dreptul de a solicita, în mod gratuit, rectificarea datelor:</strong> în situația în care există erori cu privire la datele care vă sunt prelucrate, aveți posibilitatea de a solicita corectarea lor; în cazul în care datele sunt incomplete, puteți solicita completarea lor. Rectificarea va fi comunicată fiecărui destinatar la care au fost transmise datele, cu excepția cazului în care acest lucru se dovedește imposibil sau presupune eforturi disproporționate;<br></br><br></br>

-<strong>dreptul de a solicita, în mod gratuit, restricționarea prelucrării datelor:</strong> aveți dreptul de a solicita restricționarea prelucrării datelor în următoarele situații: dacă ați contestat exactitatea datelor, pentru o perioadă care ne permite să verificăm exactitatea datelor; dacă prelucrarea este ilegală, iar dvs vă opuneți ștergerii datelor cu caracter personal, solicitând în schimb restricționarea utilizării lor; dacă operatorul nu mai are nevoie de datele cu caracter personal în scopul prelucrării, dar le solicitați pentru constatarea, exercitarea sau apărarea unui drept în instanță; dacă v-ați opus prelucrării pentru intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează asupra drepturilor dvs;<br></br><br></br>

-<strong>dreptul de a solicita, în mod gratuit, ștergerea datelor:</strong> puteți solicita ștergerea datelor prelucrate, dacă datele nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate;<br></br><br></br>

-<strong>dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automatizată a datelor:</strong>  Dvs nu veți face obiectul unei decizii bazate exclusiv pe prelucrarea automatizată a datelor.<br></br><br></br>

Toate aceste drepturi pot fi exercitate, în mod gratuit, printr-o cerere adresată organizatorului, la adresa<a href=''> springit@sisc.com </a>. De asemenea, dacă aveți nelămuriri cu privire la orice aspect ce ține de protecția datelor personale, puteți transmite o cerere la adresa<a href=''> springit@sisc.ro.</a><br></br> 
În situația în care considerați că v-au fost încălcate drepturile, aveți posibilitatea să vă adresați cu o plângere autorității de supervizare.



</div>

    </div>
  )
}
