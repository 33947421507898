import React, { useContext, useState } from "react";
import { QrReader } from "react-qr-reader";
import axios from "axios";
import "../style/QrScanner.css";
import { API_URL } from "../configure";
import { SpringContext } from "../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QRScanner = (props) => {
  const [data, setData] = useState("No result");
  const [visible, setVisible] = useState(true);
  const [payload, setPayload] = useState({});
  const [events, setEvents] = useState([]);
  const { state } =  useContext(SpringContext);

  function submit() {
    console.log(payload);
    axios({
      method: "put",
      url: `${API_URL}/api/attendance/update/${state.config.id}`,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200) setData(res.data.message);
      })
      .catch((err) => {
        if (err.response.status === 404)
          setData("Utilizatorul nu este inregistrat");
        else if (err.response.status === 500) setData("server error ;(");
      });
    setVisible(false);

    axios.get(`${API_URL}/api/attendance/getMaxParticipants/`)
    .then((res)=>{
      console.log(res.data)
      if((res.data.max + 1) % 50 == 0)
        toast(`Felicitari! Esti participantul cu numarul ${res.data.max + 1}!!!`, { type: 'success', autoClose: 10000 , style: {
          fontSize: '40px',
          padding: '4rem',
        },})

    })
  }

  function getUser(id) {
    axios({
      method: "get",
      url: `${API_URL}/api/user/${id}`,
    }).then((res) => {
      setData(`${res.data.firstName} ${res.data.lastName}`);
    });
  }

  return (
    <>
      {visible && (
        <QrReader
          className="QrReader"
          containerStyle={{
            display: "block",
            width: "100%",
          }}
          constraints={{ facingMode: "environment" }}
          onResult={(succes, error) => {
            if (succes) {
              console.log(succes)
              setPayload(() => {
                return {
                  userIds: succes.toString(),
                };
              });
              setVisible(false);
              axios({
                method: "get",
                url: `${API_URL}/api/event/getAll`,
              }).then((res) => {
                setEvents(res.data);
              });
              getUser(succes.toString());
            } else if (error) {
              //console.log(error);
            }
          }}
        />
      )}
      <p>{data}</p>

      {!visible && (
        <div>
          <div className="eventSelectorContainer">
            <label className="eventLabel" htmlFor="Eveniment">
              Eveniment:{" "}
            </label>
            <select
              name="Eveniment"
              id="eventSelector"
              onChange={(event) => {
                setPayload((prevState) => {
                  return {
                    ...prevState,
                    eventId: event.target.value,
                  };
                });
              }}
            >
              <option key="-1"></option>
              {events?.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))}
            </select>
          </div>

          <button onClick={submit}>Submit</button>

          <button
            onClick={() => {
              setVisible(true);
              setData("No result");
            }}
          >
            Start
          </button>
        </div>
      )}
      <ToastContainer />
    </>
  );
};
export default QRScanner;
