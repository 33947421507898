import ReactDOM from "react-dom";
import { QRCodeCanvas } from "qrcode.react";
import { imageListClasses } from "@mui/material";
import Logo from "../assets/SiglaBig.png";

function QrCode({ value }) {
  function download() {
    var canvas = document.getElementById("qrCodeDownload");
    var url = canvas.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "SpringITcodQR";
    link.href = url;
    link.click();
  }

  return (
    <>
      <div>
        <QRCodeCanvas
          id="qrCode"
          value={value}
          size={300}
          bgColor={"#0000"}
          level={"L"}
          imageSettings={{
            height: 90,
            width: 90,
            excavate: false,
            //src:"static/media/Sigla2.ac97ad8ab4cf14828391.png"
            src: Logo,
            //static/media/Sigla2.ac97ad8ab4cf14828391.png
            //nu pot baga Sigla.png la src poate ai tu talent ul necesar
          }}
        />
        <QRCodeCanvas
          id="qrCodeDownload"
          value={value}
          size={1200}
          bgColor={"#0000"}
          level={"L"}
          includeMargin={true}
          imageSettings={{
            height: 250,
            width: 250,
            excavate: false,
            src: Logo,
          }}
        />
      </div>
      <button className={"profile-buttons"} id="qrBtn" onClick={download}>
        DOWNLOAD QR
      </button>
    </>
  );
}
export default QrCode;
