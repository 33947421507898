import React, { useContext, useEffect, useState } from "react";
import { AccessFormContext } from "../context";
import { SpringContext } from "../App";
import axios from "axios";
import { Navbar } from "../components/Navbar";
import "../style/Workshops.css";
import { Box, Button, Modal } from "@mui/material";
import { useAddWorkshop, useAccessForm } from "../useAccesForm";
import { ConfigInput } from "../components/ConfigInput";
import Harman from "../assets/Harman.png";
import SoftServe from "../assets/SoftServe.png";
import WipsiWippete from "../assets/wip2d.png";
import MTC from "../assets/MTC-fadehand.png";
import { API_URL } from "../configure";
import { useNavigate } from "react-router-dom";
import classes from "../style/button.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer, toast } from 'react-toastify';
import { DateTimePicker } from "@mui/x-date-pickers";
import { FaMapMarkerAlt, FaClock, FaCalendarAlt, FaHourglassHalf } from "react-icons/fa";


const LogoMap = (name) => {
  switch (name) {
    case "Harman":
      return Harman;
    case "SoftServe":
      return SoftServe;
    case "Spring IT Careers":
      return WipsiWippete;
    case "Meet The Companies":
      return MTC;
  }
};

export function WorkshopsWrapper() {
  const accessFormSettings = useAccessForm();
  const navigate = useNavigate();
  if (accessFormSettings.email.value === "") {
    navigate("/profile");
  } else
    return (
      <AccessFormContext.Provider value={accessFormSettings}>
        <Worskshops />
      </AccessFormContext.Provider>
    );
}

function Workshop({ workshop }) {
  const { state, setState, setIsNavbarPage } =
    useContext(SpringContext);
  const [joined, setJoined] = useState(false);
  const [userId, setUserId] = useState(state.config.id);
  const [textBtn, setTextbtn] = useState("JOIN");

  useEffect(() => {
    axios.get(`${API_URL}/api/attendance/user/${userId}`).then((res) => {
      if (res.status === 200) {
        const temp = res.data.filter((eventId) => eventId.eventId === workshop.id);

        if (temp.length > 0) setJoined(true);
      }
    });
  }, [userId]);

  const onJoin = () => {
    axios
      .post(`${API_URL}/api/attendance/create`, {
        userId: state.config.id,
        eventId: workshop.id,
      })
      .then((res) => {
        if (res.status === 201) {
          toast("Te-ai înscris cu succes", { type: 'succes' })
          setTextbtn("JOINED");
          setJoined(true);
        }
      }).catch((err) => {
        if (err.response.status === 400) {
          console.log("user deje e inscris")
          toast("Esti deja inscris", { type: 'error' })
        } else {
          toast("Eroare de server", { type: "error" })
        }
      });
  };

  const onCancel = () => {
    setTextbtn("JOIN");
    console.log(workshop)
    axios
      .delete(`${API_URL}/api/attendance/delete/${workshop.id}`, {
        data: {
          userId: userId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setJoined(false);
        } else if (res.status === 400) {
          console.log("ceva  ")
          toast("Ești deja înregistrat!", { type: "error" })
        }
      }).catch((err) => {
        console.log(err)
      });
  };
  const dataAfisare = new Date(workshop.date);
  return (
    <div className={"workshop-container"} key={workshop.name}>
      {console.log(workshop.name)}
      <div className="workshopPozaContainer">
        <img className="workshopPoza" src={LogoMap(workshop.name)}></img>
      </div>
      <div className="workshopInfoButtons">
        <div className="wokshopInfoContainer">
          <div className="workshopInfo" id="workshopName">
            {workshop.name}
          </div>
          <div className="workshopInfo" id="workshopTime">
            <FaCalendarAlt className="workshopIcon" />
            {dataAfisare.toLocaleDateString("ro-RO")}
          </div>
          <div className="workshopInfo" id="workshopTime">
            <FaHourglassHalf className="workshopIcon" />
            {dataAfisare.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>

          <div className="workshopInfo" id="workshopLocation">
            <FaMapMarkerAlt className="workshopIcon" />
            {workshop.location}
          </div>
        </div>
        <div className="workshopButoane">
          <button className="buttonEvent" disabled={joined} onClick={onJoin}>
            {textBtn}
          </button>
          <button className="buttonEvent" disabled={!joined} onClick={onCancel}>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
}

function Worskshops() {
  const { state, setIsNavbarPage } = useContext(SpringContext);
  const [workshops, setWorkshops] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser]=useState();
  // const temp = Object.entries(state.workshops)

  useEffect(() => {
    // axios.get(`${API_URL}/api/user/${state.config.id}`).then((res) => {
    //   if (res.data.isAdmin) setIsAdmin(true);
    // });

    setIsNavbarPage(true);

    axios.get(`${API_URL}/api/event/getAll`).then((res) => {
      if (res.status === 200) {
        console.log(workshops);
        setWorkshops(res.data);
      }
    });

    axios.get(`${API_URL}/api/user/getUserById/${state.config.id}`)
    .then((res)=>{
      setUser(res.data)
      console.log(res.data)
    })
  }, []);

  const [openAddWorkshop, setOpenAddWorkshop] = useState(false);

  const onOpen = () => {
    setOpenAddWorkshop(true);
  };
  const onClose = () => {
    setOpenAddWorkshop(false);
  };

  const { name, location } = useAddWorkshop();
  const [date, setDate] = useState();
  return (
    <div className={"page"}>
      <h1 className="eventTitle">EVENTS</h1>
      <div className={"workshops-list-container"}>
      {workshops.length > 0 && workshops.map(workshop => {
          try {
            if((workshop.name === "Spring IT Careers" || workshop.name === "Meet The Companies"))
            {
              if (workshop.name !== "Harman") {
                console.log(workshop)
                return <Workshop workshop={workshop} key={workshop.name} />;
              }
              else
              {
                if(user?.studyYear !== 1)
                return <Workshop workshop={workshop} key={workshop.name} />;
              }
            }
          } catch (error) {
            console.error("Error rendering workshop:", error);
          }
        })}
      </div>
      <h1 className="eventTitle">WORKSHOPS</h1>
      <div className={"workshops-list-container"}>
        {workshops.length > 0 && workshops.map(workshop => {
          try {
            if((workshop.name !== "Spring IT Careers" && workshop.name !== "Meet The Companies"))
            {
              if (workshop.name !== "Harman") {
                console.log(workshop)
                return <Workshop workshop={workshop} key={workshop.name} />;
              }
              else
              {
                if(user?.studyYear !== 1)
                return <Workshop workshop={workshop} key={workshop.name} />;
              }
            }
          } catch (error) {
            console.error("Error rendering workshop:", error);
          }
        })}

      </div>
      {isAdmin && (
        <Button onClick={onOpen} variant={"contained"}>
          ADD WORKSHOP
        </Button>
      )}

      <Modal open={openAddWorkshop} onClose={onClose}>
        <div className={"add-workshop-container"}>
          <ConfigInput
            className="inputName"
            label={"Name"}
            value={name.value}
            onChange={name.onChange}
            error={name.error}
          />
          <ConfigInput
            className="inputLocatie"
            label={"Location"}
            value={location.value}
            onChange={location.onChange}
            error={location.error}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={date}
              onChange={(newDate) => {
                setDate(newDate);
              }}
              className="datePicker"
            />
          </LocalizationProvider>
          <Button
            variant={"contained"}
            className={classes.buttonAddWorkshop}
            onClick={() => {
              const timeStamp = Date.parse(date);
              const formatedDate = new Date(timeStamp);
              axios
                .post(`${API_URL}/api/event`, {
                  name: name.value,
                  location: location.value,
                  nrOfParticipants: 30,
                  date: formatedDate,
                })
                .then((res) => { })
                .catch((err) => { });
            }}
          >
            ADD
          </Button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}
