
import React, {useContext, useEffect, useRef} from 'react';
import {
    FaAngleRight,
    FaAngleLeft,
    FaChartBar,
    FaThLarge,
    FaShoppingCart,
    FaCog,
    FaSignOutAlt,
    FaBars
} from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import "../style/navbar2.css";
import {SpringContext} from "../App";
import axios from "axios"
import { API_URL } from "../configure";

const ICON_SIZE = 20;

function Navbar2({visible, show}) {

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                show(visible);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

    const {state} = useContext(SpringContext)

    const onLogOut = () => {
        axios.post(`${API_URL}/api/user/logout`)
    }
    const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

    return (
        <>
        <div ref={wrapperRef}>
            <div className="mobile-nav">
                <button
                    className="mobile-nav-btn"
                    onClick={() => show(!visible)}
                >
                    <FaBars size={24}  />
                </button>
            </div>
            
            <nav  className={!visible ? 'navbar' : ''}>
                <button
                    type="button"
                    className="nav-btn"
                    onClick={() => show(!visible)}
                >
                    { !visible
                        ? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
                </button>
                <div>
                    <div className="links nav-top">
                        <NavLink to="/profile" className="nav-link">
                            <FaThLarge size={ICON_SIZE} />
                            <span>Profile</span>
                        </NavLink>
                        <NavLink to="/workshops" className="nav-link">
                            <FaChartBar size={ICON_SIZE} />
                            <span>Events </span>
                        </NavLink>
                        {/*<NavLink to="/orders" className="nav-link">*/}
                        {/*    <FaShoppingCart size={ICON_SIZE} />*/}
                        {/*    <span>Orders</span>*/}
                        {/*</NavLink>*/}
                <div className="links">
                    <a href="https://springit.sisc.ro" className="nav-link" onClick={onLogOut}>
                        <FaSignOutAlt size={ICON_SIZE} />
                        <span>Logout</span>
                    </a>
                </div>
                    </div>
                </div>

            </nav>
            </div>
        </>
    );
}

export default Navbar2;
