import React, { useContext } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus } from '@fortawesome/free-solid-svg-icons'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import "../style/file-upload.css";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { SpringContext } from "../App";
import { toast, ToastContainer } from "react-toastify";
import { API_URL } from "../configure";

const FileUpload = ({ file, setFile, setIdCV, removeFile, hasFile, idCV }) => {
  const { state } = useContext(SpringContext);
  // const { getRootProps, getInputProps } = useDropzone({});
  const updateHandler = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFile(file);

    // upload file
    const formData = new FormData();
    formData.append("file", file);
    for (const pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    axios
      .put(`${API_URL}/api/cv/updateCV/${idCV}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        file.isUploading = false;
        setFile(file);
        toast("Upload reusit");
      })
      .catch((err) => {
        // inform the user
        console.error(err);
        toast("Upload nereusit");
        removeFile(file.name);
      });
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFile(file);

    // upload file
    const formData = new FormData();
    formData.append("file", file);
    // for (const pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    axios
      .post(`${API_URL}/api/cv/upload/${state.config.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        file.isUploading = false;
        setFile(file);
        setIdCV(res.data.id)
        toast("Upload reusit");
      })
      .catch((err) => {
        // inform the user
        console.error(err);
        toast("Upload nereusit");
        removeFile(file.name);
      });
  };

  return (
    <>
      <div className="file-card">
        {(file || hasFile) && (
          <CheckCircleIcon
            className="checkmark"
            sx={{ color: "green", fontSize: "50px" }}
          />
        )}
        {!idCV ? (
          <>
            <div className="file-inputs">
              <input
                type="file"
                accept="application/pdf"
                onChange={uploadHandler}
              />

              <button className="buttonCV">
                <i>
                  <DescriptionIcon />
                </i>
                UPLOAD CV
              </button>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="file-inputs">
              <input
                type="file"
                accept="application/pdf"
                onChange={updateHandler}
              />

              <button className="buttonCV">
                <i>
                  <DescriptionIcon />
                </i>
                UPDATE CV
              </button>
            </div>
          </>
        )}

        <p className="main">Supported files</p>
        <p className="info">PDF</p>
      </div>
      <ToastContainer />
    </>
  );
};

export default FileUpload;
