import React, { useContext, useEffect, useState, useRef } from "react";
import { useAccessForm } from "../useAccesForm";
import { AccessFormContext } from "../context";
import "../style/Profile.css";
import "../style/EditInput.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import QrCode from "../components/qrcode";
import QRScanner from "../components/QrReader";
import { Navbar } from "../components/Navbar";
import { EditField } from "../components/edit-field";
import FileUpload from "../components/FileUpload";
import { FileItem } from "../components/FileItem";
import axios from "axios";
import { SpringContext } from "../App";
import { config } from "@fortawesome/fontawesome-svg-core";
import { API_URL } from "../configure";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import Modal from "../components/Modal";
import WipProfile from "../assets/wip-profile.png";
import WipetteProfile from "../assets/wipette-profile.png";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { toast, ToastContainer } from "react-toastify";


const cookies = new Cookies(null, { path: "/" });

export function ParticipantProfileWrapper() {
  const accessFormSettings = useAccessForm();
  return (
    <AccessFormContext.Provider value={accessFormSettings}>
      <ParticipantProfile />
    </AccessFormContext.Provider>
  );
}

export function ParticipantProfile() {
  const { state, setState, setIsNavbarPage } = useContext(SpringContext);
  const { first_name, name, phone, study_year, university, email } =
    useContext(AccessFormContext);
  const [file, setFile] = useState(null);
  const [hasFile, setHasFile] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [idPoza, setIdPoza] = useState(0);
  const [idCV, setIdCV] = useState(null);
  const removeFile = (filename) => {
    setFile(null);
    setIdCV(null);
    toast("Stergere CV reusita");
  };
  const deleteFileHandler = (_name) => {
    axios
      .delete(`${API_URL}/api/CV/delete/${idCV}`)
      .then((res) => removeFile(_name))
      .catch((err) => console.error(err));
  };
  const updateProfilePicture = async (imageId) => {
    console.log({ idPoza: idPoza });
    await axios
      .put(`${API_URL}/api/user/updateUser/${state.config.id}`, {
        idPoza: imageId,
      })
      .then((res) => {
        console.log(res.data);
        // const goodState = {
        //     id: res.data.id,
        //     email: res.data.email,
        //     first_name: res.data.firstName,
        //     name: res.data.lastName,
        //     phone: res.data.phone,
        //     university: res.data.university,
        //     study_year: res.data.studyYear,
        // }
        // setState({...state, config: {...state.config, ...goodState}})
      })
      .catch((err) => console.log(err));
  };
  const saveNewProfile = () => {
    axios
      .put(`${API_URL}/api/user/updateUser/${state.config.id}`, {
        firstName: state.config.first_name,
        lastName: state.config.name,
        email: state.config.email,
        phone: state.config.phone,
        university: state.config.university,
        studyYear: state.config.study_year,
      })
      .then((res) => {
        // const goodState = {
        //     id: res.data.id,
        //     email: res.data.email,
        //     first_name: res.data.firstName,
        //     name: res.data.lastName,
        //     phone: res.data.phone,
        //     university: res.data.university,
        //     study_year: res.data.studyYear,
        // }
        // setState({...state, config: {...state.config, ...goodState}})
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setIsNavbarPage(true);

    if (state.config.id !== "0")
      window.localStorage.setItem("profile-id", state.config.id);

    // console.log(cookies.get('bearer'));

    const localStorageId = cookies.get("bearer");

    try {
      axios
        .get(
          `${API_URL}/api/user/getUserById/${localStorageId || state.config.id}`
        )
        .then((res) => {
          const goodState = {
            id: res.data.id,
            email: res.data.email,
            first_name: res.data.firstName,
            name: res.data.lastName,
            phone: res.data.phone,
            university: res.data.university,
            study_year: res.data.studyYear,
            admin: res.data.isAdmin,
            partner: res.data.isPartner,
            idPoza: res.data.idPoza,
          };
          setState({ ...state, config: { ...state.config, ...goodState } });

          if (goodState.idPoza == 0) {
            setIdPoza(0);
            setSelectedImage(WipProfile);
          } else {
            setIdPoza(1);
            setSelectedImage(WipetteProfile);
          }

          if (res.data.cvName) {
            setHasFile(true);
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    } catch (err) {
      // console.log(err)
    }

    try {
      axios
        .get(
          `${API_URL}/api/cv/getCvByUserId/${localStorageId || state.config.id}`
        )
        .then((res) => {
          const cv = {
            id: res.data.id,
            name: res.data.name,
            mimeType: res.data.mimeType,
            data: res.data.data,
          };

          if (cv) {
            setIdCV(cv.id);
            setFile(cv);
          }
        });
    } catch (err) { }

    const savedImage = localStorage.getItem("selectedImage");
    if (savedImage) {
      setSelectedImage(savedImage);
    }
  }, []);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleImageClick = (imageId, poza) => {
    console.log("Buton apasat", imageId, poza);

    setIdPoza(imageId);

    updateProfilePicture(imageId);
    //localStorage.setItem("selectedImage", imageSrc);
  };

  return (
    <div className={"profile-page"}>
      <div className={"profile-top"}>
        <div className={"profile-background-resp"}></div>
        <div className={"about-user"}>
          <img
            src={idPoza == 0 ? WipProfile : WipetteProfile}
            alt=""
            className={"image-profile"}
            onClick={openModal}
          />
          <CreateRoundedIcon
            className="icon-change-image"
            onClick={openModal}
          ></CreateRoundedIcon>
          <Modal show={showModal} handleClose={closeModal}>
            <img
              src={WipProfile}
              width={150}
              alt=""
              onClick={() => handleImageClick(0, WipProfile)}
            />
            <img
              src={WipetteProfile}
              width={150}
              alt=""
              onClick={() => handleImageClick(1, WipetteProfile)}
            />
          </Modal>
          <h1>
            <span>{state.config.first_name?.toUpperCase()}</span>
          </h1>
          {/* <h3>#{state.config.id}</h3> */}
        </div>

        {!state.config.partner && (
          <div className={"cv-container"}>
            <FileUpload
              file={file}
              setFile={setFile}
              setIdCV={setIdCV}
              hasFile={hasFile}
              removeFile={removeFile}
              idCV={idCV}
            />
            {file && <FileItem file={file} deleteFile={deleteFileHandler} />}
          </div>
        )}
      </div>

      <div className={"profile-bottom"}>
        <div className={"profile-settings"}>
          <EditField field={email} label={"Email"} icon={<EmailIcon />} />
          {/* <EditField field={name} label={"Name"} icon={<BadgeIcon />} />
        <EditField
          field={first_name}
          label={"First Name"}
          icon={<BadgeIcon />}
        /> */}
          <EditField
            field={phone}
            label={"Phone number"}
            icon={<ContactPhoneIcon />}
          />
          <EditField
            field={university}
            label={"University"}
            icon={<AccountBalanceIcon />}
          />
          <EditField
            field={study_year}
            label={"Study Year"}
            icon={<PermContactCalendarIcon />}
          />

          {/* <button className={"profile-buttons"} onClick={saveNewProfile}>
            SAVE EDITS
          </button> */}
          {/* <QrCode value={`http://localhost:7070/api/attendance/${state.config.id}`} /> poate merge bagat si aici*/}
        </div>

        {!state.config.partner && (
          <div className="qr-container">
            <QrCode value={`${state.config.id}`} />
          </div>
        )}
      </div>

      {state.config.admin && (
        <div className="cv-container">
          <h1>Scanner</h1>
          <QRScanner />
        </div>
      )}
    </div>
  );
}
