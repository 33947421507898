import React, { useEffect, useRef } from "react";
import "../style/Modal.css";
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ handleClose, show, children }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleClose]);

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main up" ref={modalRef}>
        {children}
        <button className="modal-close-button" onClick={handleClose}> <CloseIcon></CloseIcon> </button>
      </section>
    </div>
  );
};

export default Modal;
