import React, { useContext, useMemo, useState } from "react";
import { SpringContext } from "../App";
import { ConfigInput } from "./ConfigInput";
import { Button } from "@mui/material";


export function EditField({ field, icon, label }) {
    const { state } = useContext(SpringContext)
    const [isEditMode, setIsEditMode] = useState(false)
    const initialText = useMemo(() => field.value, [])
    const enableEditMode = () => {
        setIsEditMode(true)
    }

    const disableEditMode = () => {
        setIsEditMode(false)
        field.onChange(initialText)
    }
    return <div className={'edit-container'}>
        <div className={'title-container'}>
            <div>{icon}</div>
            <span>{label}</span>
        </div>
        <div className={'edit-wrapper'}>
            <div className={'input-wrapper'}>
                {isEditMode ? <ConfigInput value={field.value} onChange={field.onChange} error={field.error} /> :
                    <span>{field.value}</span>}
            </div>
            {/* <div className={'button-wrapper'}>
                {isEditMode ?
                    <Button color={'error'} onClick={disableEditMode} disabled={!!field.error}>CANCEL</Button> :
                    <Button onClick={enableEditMode}>EDIT</Button>}
            </div> */}
        </div>
    </div>
}