import { useState } from "react";
import { ConfigInput } from "../components/ConfigInput";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../configure";
import { ToastContainer, toast } from 'react-toastify';


export function ForgotPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const params = useParams()

  const handleChange = (name, value) => {
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "repeatPassword") {
      setRepeatPassword(value);
    }
  };

  const onResetPassword = () => {
    if (newPassword !== repeatPassword) {
      toast("Parolele nu se potrivesc! Încearcă din nou!", { type: "error" })
      setError("Passwords do not match");
      return;
    }
    toast("Ai resetat parola cu succes!", { type: 'succes' })
    console.log('Passwords match!!');
    setError("");

    axios.post(`${API_URL}/api/user/updatePassword/${params.token}`, {
      password: newPassword,
    })
    .then((res) => {
        console.log(res.data)
    })
  };

  return (
    <>
      <div className="login-page">
        <div className={'form login-form'}>
          <label htmlFor="chk" aria-hidden="false" className={'bigger-label'}>
            Recover Account
          </label>
          <div className={'password-reset-container'}>
            <ConfigInput
              type={"password"}
              label={"New Password"}
              value={newPassword}
              onChange={(value) => handleChange("newPassword", value)}
              error={error}
              name="newPassword"
              className={'login-input-login'}
            />
            <ConfigInput
              type={"password"}
              label={"Repeat Password"}
              value={repeatPassword}
              onChange={(value) => handleChange("repeatPassword", value)}
              error={error}
              name="repeatPassword"
              className={'login-input-login'}
            />
          </div>
          <button className={'button'} onClick={onResetPassword}>
            Reset Password
          </button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
