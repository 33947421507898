import { createContext, Dispatch, SetStateAction } from "react";

export const AccessFormContext = createContext({
  email: null,
  first_name: null,
  name: null,
  phone: null,
  university: null,
  study_year: null,
  password: null,
}
);
export const LoginContext = createContext({
  email: null,
  password: null,
}
);
