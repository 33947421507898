import {Button, Input} from "@mui/material";
import {useContext, useState} from "react";
import {SpringContext} from "./App";

function Participant({id}) {
    return<div>{id}
        <Button>DOWNLOAD</Button>
    </div>
}


export function MeetTheCompanies()  {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const {state} = useContext(SpringContext)
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };
    let fileReader
    const handleFileRead = (e) => {
        const content = fileReader.result;
        // … do something with the 'content' …
    };

    const handleSubmission = () => {
        fileReader = new FileReader()
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(selectedFile);
    };

    return <div>
        <Input type="file" name="file" onChange={changeHandler}/>
        <div>
            <Button onClick={handleSubmission}>Submit</Button>
        </div>

        {/* {state.meetTheCompanies.registered.map((id: string) => <Participant id={id} key={id}/>) } */}
    </div>
}

