import {useContext, useEffect, useState} from "react";
import {SpringContext} from "./App";


export function validate(setState , fieldId , fieldValue, validators) {
    for (const validator of validators) {
        const [valid, errorMessage] = validator(fieldValue);

        if (!valid) {
            setState((prev) => {
                const invalidFields = new Map(prev.invalidFields);
                invalidFields.set(fieldId, errorMessage);
                return {...prev, invalidFields};
            })
            return;
        }
    }
    setState((prev) => {
        const invalidFields = new Map(prev.invalidFields);
        invalidFields.delete(fieldId);
        return {...prev, invalidFields};
    })
}


const fields = {
    id : 'id',
    email : 'email',
    firstName : 'first_name',
    name : 'name',
    phone : 'phone',
    university : 'university',
    studyYear : 'study_year',
    password : "password",
    location : "location",
    date : "date"
}

export function required(value) {
    const valid = !!value;
    return [valid, valid ? "" : "Field is required."];
}

export function useAccessForm() {
    const {state, setState} = useContext(SpringContext)

    useEffect(() => {
        validate(setState, fields.email, state.config?.email, [required])
        validate(setState, fields.firstName, state.config?.first_name, [required])
        validate(setState, fields.name, state.config?.name, [required])
        validate(setState, fields.phone, state.config?.phone, [required])
        validate(setState, fields.university, state.config?.university, [required])
        validate(setState, fields.studyYear, state.config?.study_year, [required])
    }, [])

    return {
        [fields.email]: {
            value: state.config?.email,
            onChange: (email) => {
                validate(setState, fields.email, email, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, email}}
                })
            },
            error: state.invalidFields.get(fields.email)
        },
        [fields.firstName]: {

            value: state.config?.first_name,
            onChange: (first_name) => {
                validate(setState, fields.firstName, first_name, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, first_name}}
                })
            },
            error: state.invalidFields.get(fields.firstName)
        },
        [fields.name]: {
            value: state.config?.name,
            onChange: (name) => {
                validate(setState, fields.name, name, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, name}}
                })
            },
            error: state.invalidFields.get(fields.name)
        },
        [fields.phone]: {
            value: state.config?.phone,
            onChange: (phone) => {
                validate(setState, fields.phone, phone, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, phone}}
                })
            },
            error: state.invalidFields.get(fields.phone)
        },
        [fields.university]: {
            value: state.config?.university,
            onChange: (university) => {
                validate(setState, fields.university, university, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, university}}
                })
            },
            error: state.invalidFields.get(fields.university)
        },
        [fields.studyYear]: {
            value: state.config?.study_year,
            onChange: (study_year) => {
                validate(setState, fields.studyYear, study_year, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, study_year}}
                })
            },
            error: state.invalidFields.get(fields.studyYear)
        },
        [fields.password]: {
            value: state.config?.password,
            onChange: (password) => {
                validate(setState, fields.studyYear, password, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, password}}
                })
            },
            error: state.invalidFields.get(fields.password)
        }

    }
}


export function useLogin() {
    const {state, setState} = useContext(SpringContext)

    useEffect(() => {
        validate(setState, fields.email, state.login?.email, [required])
        validate(setState, fields.password, state.login?.password, [required])
    }, [])
    return {
        [fields.email]: {
            value: state.config?.email,
            onChange: (email) => {
                validate(setState, fields.email, email, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, email}}
                })
            },
            error: state.invalidFields.get(fields.email)
        },
        [fields.password]: {

            value: state.config?.password,
            onChange: (password) => {
                validate(setState, fields.password, password, [required])
                setState((prev) => {
                    return {...prev, config: {...prev.config, password}}
                })
            },
            error: state.invalidFields.get(fields.password)
        },
    }
}

export function useAddWorkshop()  {
    const [newWorkshop, setNewWorkshop] = useState({name: "", location: "", date:""})
    const {state, setState} = useContext(SpringContext)


    return {
        [fields.name]: {
            value: newWorkshop.name,
            onChange: (name) => {
                validate(setNewWorkshop, fields.name, name, [required])
                setNewWorkshop((prev) => {
                    return {...prev, name}
                })
            },
            error: state.invalidFields.get(fields.name)
        },
        [fields.location]: {
            value: newWorkshop.location,
            onChange: (location) => {
                validate(setNewWorkshop, fields.location, location, [required])
                setNewWorkshop((prev) => {
                    return {...prev,  location}
                })
            },
            error: state.invalidFields.get(fields.location)
        },
        [fields.date]: {
            value: newWorkshop.date,
            onChange: (date) => {
                validate(setNewWorkshop, fields.date, date, [required])
                setNewWorkshop((prev) => {
                    return {...prev,  date}
                })
            },
            error: state.invalidFields.get(fields.date)
        }

    }
}