import { useAccessForm, useLogin } from "../useAccesForm";
import { AccessFormContext, LoginContext } from "../context";
import React, { useContext, useState } from "react";
import { SpringContext } from "../App";
import { ConfigInput } from "../components/ConfigInput";
import { SelectInput } from "../components/SelectInput";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import '../style/Login.css'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../configure";
import { EmailIcon, HttpsIcon, PersonIcon, PhoneIcon, SchoolIcon, DateRangeIcon } from '@mui/icons-material';



function Login() {
    const [checked, setChecked] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const { state, setState } = useContext(SpringContext)
    const { email, password } = useContext(LoginContext)
    const { first_name, name, phone, study_year, university } = useContext(AccessFormContext) //email, password
    const navigate = useNavigate()

    const onSignUp = () => {
        axios.post(`${API_URL}/api/user/register`, {
            firstName: first_name.value,
            lastName: name.value,
            email: email.value,
            password: password.value,
            phone: phone.value,
            studyYear: study_year.value,
            university: university.value,
        }).then(() => {
            toast("Congrats! You signed up! Now go and log in!", { type: 'success' })
        }).catch((e) => {
            toast(`${e.response.data}!`, { type: 'error' })
            console.error(e.response.data)
        })
    }


    const onLogin = () => {
        axios.post(`${API_URL}/api/user/login`, {
            email: email.value,
            password: password.value
        }, { withCredentials: true }).then((res) => {
            if (res.status === 202) {
                setState({ ...state, config: { ...state.config, id: res.data.userId } })
                navigate('/profile')
            }
        }).catch((e) => {
            console.error(e)
            toast("Oh no, something went wrong!", { type: 'error' })
        })
    }

    const [isSignUpMode, setIsSignUpMode] = useState(false);

    const handleSignUpClick = () => {
        console.log("sign up clicked");
        setIsSignUpMode(true);
    };

    const handleSignInClick = () => {
        console.log("sign in clicked");
        setIsSignUpMode(false);
    };

    const onRecover = () => {
        axios.post(`${API_URL}/api/user/forgotPassword`, {
            email: email.value,
        })
            .then((res) => {
                console.log(res.body)
            })
    }

    return (
        <div className={'login-page'}>
            <div className={`login-main-container ${isSignUpMode ? "sign-up-mode" : ""}`}>
                {/* <input type="checkbox" id="chk" aria-hidden="true" /> */}
                <div className={'toggle-container'}>

                    <div className={'toggle'}>
                        <div className={'toggle-panel toggle-left'}>
                            <h1>Bine ai revenit la SpringIT!</h1>
                            <p>Aici te poți conecta, însă dacă nu ai cont, desigur că te poți înscrie.</p>
                            <button className={'hidden button'} id="sign-in-btn" onClick={handleSignInClick}>Sign Up</button>
                        </div>
                        <div className={'toggle-panel toggle-right'}>
                            <h1>Bine ai venit la SpringIT!</h1>
                            <p> Dacă nu ai cont, te poți înscrie aici sau dacă ai deja unul te poți conecta.</p>
                            <button className={'hidden button'} id="sign-up-btn" onClick={handleSignUpClick}>Login</button>
                        </div>
                    </div>
                </div>

                <div className={'signup-container'}>
                    <label htmlFor="chk" aria-hidden="true" className={'bigger-label'}>Sign up</label>
                    <div className={'form signup-form'}>
                        <div className="signup-left">
                            <ConfigInput label={"Email"} value={email.value} onChange={email.onChange} error={email.error} className={'login-input'} inputColor={'black'} />
                            <ConfigInput label={"First Name"} value={first_name.value} onChange={first_name.onChange} error={first_name.error} className={'login-input'} inputColor={'black'} />
                            <ConfigInput label={"Name"} value={name.value} onChange={name.onChange} error={name.error} className={'login-input'} inputColor={'black'} />
                            <ConfigInput label={"Phone"} value={phone.value} onChange={phone.onChange} error={phone.error} className={'login-input'} inputColor={'black'} />
                        </div>
                        <div className="signup-right">
                            {/* <ConfigInput label={"University"} value={university.value} onChange={university.onChange} error={university.error} className={'login-input'} inputColor={'black'} /> */}
                            <SelectInput label={"University"} value={university.value} onChange={university.onChange} error={university.error} className={'login-select'}
                                option={[
                                    { value: "ASE", label: "Academia de Studii Economice din București" },
                                    { value: "UPB", label: "Universitatea Națională de Știință și Tehnologie Politehnica București" },
                                    { value: "UniBuc", label: "Universitatea din București" },
                                    { value: "Alta", label: "Alta variantă" },
                                ]} />
                            {/* <ConfigInput label={"Study Year"} value={study_year.value} onChange={study_year.onChange} error={study_year.error} className={'login-input'} inputColor={'black'} /> */}
                            <SelectInput label={"Study Year"} value={study_year.value} onChange={study_year.onChange} error={study_year.error} className={'login-select'}
                                option={[
                                    { value: 1, label: "Anul I" },
                                    { value: 2, label: "Anul II" },
                                    { value: 3, label: "Anul III" },
                                    { value: 4, label: "Anul IV" },

                                ]} />

                            <ConfigInput type={'password'} label={"Password"} value={password.value} onChange={password.onChange} error={password.error} className={'login-input'} inputColor={'black'} />
                            <div className="gdpr-sign-up"><input type="checkbox" className="gdpr-checkbox" onClick={(e) => { setChecked((e.target).value) }} id="" /> <p> Accept <Link to="/gdpr" className="gdpr-sign-up-link" target="_blank" >GDPR </Link></p></div>
                        </div>
                        <button className={'button button-rep-signup'} disabled={state.invalidFields.size > 0 || checked === false} onClick={onSignUp}>Sign up</button>
                    </div>
                    <button className={' button button-signup'} disabled={state.invalidFields.size > 0 || checked === false} onClick={onSignUp}>Sign up</button>

                </div>

                <div className={'login-container'}>
                    <div className={'form login-form'}>
                        {isForgot
                            ?
                            <div>
                                <label htmlFor="chk" aria-hidden="false" className={'bigger-label'}>Recover Account</label>
                                <ConfigInput label={"Email"} value={email.value} onChange={email.onChange} error={email.error} className={'login-input-login'} />
                                <button className={'button'} onClick={onRecover}>Recover</button>
                            </div>
                            :
                            (
                                <div>
                                    <label htmlFor="chk" aria-hidden="false" className={'bigger-label'}>Login</label>
                                    <ConfigInput label={"Email"} value={email.value} onChange={email.onChange} error={email.error} className={'login-input-login'} />
                                    <ConfigInput type={"password"} label={"Password"} value={password.value} onChange={password.onChange}
                                        error={password.error} className={'login-input-login'} />
                                    <button className={'button'} onClick={onLogin}>Login</button>
                                </div>
                            )

                        }
                        <button className={'button forgot-pass'} onClick={() => { setIsForgot(!isForgot) }}>
                            {!isForgot ? <>Forgot Password</> : <>Back to Login</>}
                        </button>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
export function LoginWrapper() {
    const value = useLogin()
    const accessFormSettings = useAccessForm()
    return <div>
        <AccessFormContext.Provider value={accessFormSettings}>
            <LoginContext.Provider value={value}>

                <Login />
            </LoginContext.Provider>
        </AccessFormContext.Provider>
    </div>
}