import {  MenuItem, TextField } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";

export const SelectInput = ({
  label,
  value,
  onChange,
  className,
  error,
  initialError = false,
  placeholder,
  option, 
  inputColor,
}) => {
  const onChangeWrapper = useCallback(
    (evt) => {
      onChange?.(evt.target.value);
    },
    [onChange]
  );

  const [showError, setShowError] = useState(initialError);
  const displayError = useCallback(() => {
    setShowError(true);
  }, []);

  return (
    <div style={{ marginBottom: "24px" }} className={className}>
       {/* <InputLabel>{label}</InputLabel> */}
      <TextField
      label={label}
      select
        value={value}
        onChange={onChangeWrapper}
        onBlur={displayError}
        error={showError && !!error}
        style={{ width: "100%" }}
      >
        {option.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {showError && error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};
